<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування меню" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs4" v-if="tabValue === 0">
                <va-input
                  label="label *"
                  v-model="label"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="Alias"
                  v-model="alias"
                  @input="delete errors.alias"
                  :messages="['The recommended number of characters is 15']"
                  :error="!!errors.alias"
                  :error-messages="errors.alias"
                />
                <va-input
                  label="Position"
                  v-model.number="position"
                  @input="delete errors.position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                />
                <va-toggle label="In header" small v-model="in_header"/>
                <va-toggle label="In footer" small v-model="in_footer"/>
                <va-toggle label="Published" small v-model="published"/>
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Title"
                  v-model="seoTitle"
                />
                <va-input
                  label="Description"
                  v-model="seoDescription"
                />
                <va-input
                  label="Keywords"
                  v-model="seoKeywords"
                />
                <div style="margin-bottom: 15px;">
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-card class="mb-3" title="Image *">
                  <div v-if="seoImageExisting">
                    <a style="display: block;" target="_blank" :href="seoImageExisting"><img style="max-width: 400px;" :src="seoImageExisting"></a>
                    <div><va-button small color="danger" @click="seoImageExisting = deleteImage(seoImageId) ? '' : seoImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    @input="delete errors.seoImage"
                    type="gallery"
                    dropzone
                    v-model="seoImage"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.seoImage" style="color: red;">{{ this.errors.seoImage[0] }}</div>
                  <va-button @click="uploadImage(seoImage[0])">Завантажити</va-button>
                </va-card>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      label: '',
      alias: '',
      position: '',
      published: false,
      in_header: false,
      in_footer: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageExisting: '',
      seoImageId: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_BGK_API_URL}/${this.locale}/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      errors: [],

      tabTitles: ['Main', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.seoImageNew = response.data.patch
          this.seoImageId = response.data.id
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/upload/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      axios.put(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/menu/${this.$attrs.id}`,
        {
          label: this.label,
          alias: this.alias,
          position: this.position,
          published: !!this.published,
          in_footer: !!this.in_footer,
          in_header: !!this.in_header,
          seo_title: this.seoTitle,
          seo_desc: this.seoDescription,
          seo_keywords: this.seoKeywords,
          seo_text: this.seoText,
          seo_image: this.seoImageNew,
        })
        .then(response => {
          console.log(response)
          this.$router.push({ name: 'bgk-menu' })
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/menu/${this.$attrs.id}`)
        .then(response => {
          this.label = response.data.label
          this.alias = response.data.alias
          this.position = response.data.position
          this.published = !!response.data.published
          this.in_footer = !!response.data.in_footer
          this.in_header = !!response.data.in_header
          this.seoTitle = response.data.seo_title
          this.seoDescription = response.data.seo_desc
          this.seoKeywords = response.data.seo_keywords
          this.seoText = response.data.seo_text
          this.seoImageNew = response.data.seo_image
          this.seoImageExisting = process.env.VUE_APP_BGK_URL + response.data.seo_image
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
